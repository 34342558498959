
<template>
    <Base>
    <header id="header">
        <div class="container">
            <div class="row">
                <div class="col-md-4 relative">
                    <div class="logo">
                        <router-link to="/">
                            <img alt="logo" src="../../assets/images/logo.svg" />
                        </router-link>
                    </div>
                    <span class="toggle-menu-btn d-md-none">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </div>
                <div class="col-md-8 text-right">
                    <nav id="navigation">
                        <ul>
                            <!--<li>
                              <router-link to="/team">
                                about team TOMM®
                              </router-link>
                            </li>-->
                            <li>
                                <router-link to="/what-we-have-been-up-to">
                                    What we've been up to
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/privacy-policy">
                                    Privacy Policy
                                </router-link>
                            </li>
                            <li class="d-md-none">
                                <router-link to="/contact" class="current">Contact us</router-link>
                            </li>
                            <li class="d-none d-md-inline-block" @click="() => this.$analytics.fbq.event('Contact')">
                                <router-link to="/contact" id='contact-button' class="btn button1">
                                    Contact us
                                </router-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>

    <div class="scrollable-content">
        <div class="section contact-us h-100 d-flex flex-wrap align-items-center">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="box1 secondary bg-white rounded-2 single-person">
                            <div v-if="!showSuccessMessage" class="form">
                                <form method='post' action="#" @submit.prevent="sendContact">
                                    <header class="text-center text-md-start">
                                        <h2 class="display-6">We’re here to help.</h2>
                                    </header>
                                    <div class="row row-alt">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name='firstname' class="form-control forced" placeholder="First name *" v-model="form.firstname">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name='lastname' class="form-control forced" placeholder="Last name *" v-model="form.lastname">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-alt">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name='profession' class="form-control" placeholder="Profession" v-model="form.profession">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name='organisation' class="form-control forced" placeholder="Organisation *" v-model="form.organisation">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-alt">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name='phone' class="form-control forced" placeholder="Phone number *" v-model="form.phone">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name='organisationPhone' class="form-control" placeholder="Phone number (Organisation)" v-model="form.organisationPhone">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-alt">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="email" name='email' class="form-control forced" required placeholder="Email *" v-model="form.email">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-alt">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <textarea name='message' class="form-control forced" placeholder="How can we help you? *" v-model="form.message" ref="input" rows="1" :style="inputStyle"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-alt">
                                        <div class="col-md-12">
                                            <div class="form-group mb-0 text-center text-md-start">
                                                <button type="submit" id='contact-submit' class="btn button1 alt" @click="() => this.$analytics.fbq.event('Lead')">
                                                    send us a message <i class="icon circle right-arrow v-center"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14"><g><g><path fill="none" stroke="#1b7597" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="2" d="M1.339 7.068H16.05" /></g><g><path fill="none" stroke="#1b7597" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="2" d="M10.534 1.55v0l5.517 5.518v0l-5.517 5.517v0" /></g></g></svg></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> 
                            <div v-else class="successMessage">
                                <h3>Thank you for your email.</h3>
                                <h3>A representative from TOMM® Support Team will be in touch with you as soon as possible. </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="copyright-footer">
                    <p>©Measuring Outcomes Ltd 2021</p>
                </footer>
            </div>
        </div>
    </div>

    <footer id="footer">
        <div class="container d-flex align-items-center justify-content-between">

            <p class="copyright">©Measuring Outcomes Ltd 2021</p>
            <div class="secondary-navigation">
                <ul class="menu">
                    <li>
                        <a href="/#!principles">
                            <span>principles</span>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny-ps" viewBox="0 0 30 30" width="30" height="30">
                                <g>
                                    <g>
                                        <g><path fill="#1381a2" fill-rule="evenodd" class="shp0" d="M7.3 14.76C7.3 10.67 10.61 7.36 14.69 7.36C18.78 7.36 22.09 10.67 22.09 14.76C22.09 18.85 18.78 22.16 14.69 22.16C10.61 22.15 7.3 18.84 7.3 14.76ZM10.63 20.02C11.2 20.47 11.85 20.82 12.54 21.06C13.24 21.3 13.96 21.42 14.7 21.42C15.43 21.42 16.16 21.3 16.85 21.06C17.54 20.82 18.19 20.47 18.77 20.02C18.76 19.6 18.69 19.18 18.56 18.79C18.42 18.39 18.23 18.01 17.98 17.68C17.73 17.34 17.43 17.04 17.09 16.79C16.75 16.55 16.38 16.36 15.98 16.22C15.91 16.2 15.85 16.16 15.8 16.1C15.76 16.04 15.73 15.97 15.73 15.9C15.72 15.83 15.74 15.76 15.77 15.7C15.81 15.63 15.86 15.58 15.93 15.55C16.96 15.02 17.51 13.86 17.24 12.72C16.98 11.59 15.98 10.78 14.82 10.77C13.65 10.75 12.63 11.53 12.34 12.66C12.04 13.78 12.56 14.96 13.58 15.52C13.64 15.55 13.7 15.6 13.73 15.67C13.76 15.73 13.78 15.81 13.77 15.88C13.76 15.95 13.74 16.02 13.69 16.07C13.64 16.13 13.58 16.17 13.51 16.19C13.1 16.32 12.71 16.5 12.36 16.75C12.01 16.99 11.7 17.29 11.44 17.63C11.18 17.97 10.98 18.36 10.84 18.76C10.7 19.17 10.63 19.59 10.62 20.02L10.63 20.02ZM14.69 8.1C13.38 8.1 12.1 8.48 11.01 9.21C9.92 9.93 9.07 10.96 8.56 12.17C8.05 13.38 7.91 14.71 8.15 16C8.4 17.28 9.02 18.47 9.94 19.41C9.99 19.02 10.09 18.63 10.24 18.27C10.39 17.9 10.59 17.55 10.82 17.23C11.05 16.92 11.33 16.63 11.63 16.38C11.94 16.12 12.27 15.91 12.63 15.74C11.61 14.84 11.26 13.4 11.75 12.13C12.24 10.86 13.47 10.02 14.83 10.04C16.19 10.06 17.4 10.93 17.86 12.21C18.31 13.49 17.92 14.93 16.87 15.8C17.22 15.97 17.54 16.19 17.83 16.44C18.12 16.69 18.38 16.97 18.61 17.29C18.83 17.6 19.02 17.94 19.16 18.3C19.3 18.65 19.4 19.03 19.46 19.41C20.37 18.47 20.99 17.28 21.24 16C21.48 14.71 21.34 13.38 20.83 12.17C20.33 10.96 19.47 9.93 18.38 9.21C17.29 8.48 16.01 8.1 14.69 8.1Z" /></g>
                                        <g><path fill-rule="evenodd" class="shp0" d="M17.06 2.4C17.06 2.7 17 3.01 16.88 3.29C16.76 3.57 16.59 3.83 16.37 4.05C16.15 4.26 15.89 4.44 15.61 4.55C15.33 4.67 15.02 4.73 14.72 4.73L14.71 4.73C13.76 4.72 12.91 4.15 12.55 3.27C12.19 2.39 12.4 1.39 13.07 0.72C13.74 0.06 14.75 -0.14 15.62 0.23C16.5 0.59 17.06 1.45 17.06 2.4ZM13.11 2.4C13.11 2.61 13.15 2.82 13.23 3.01C13.31 3.2 13.43 3.38 13.58 3.53C13.73 3.68 13.91 3.79 14.1 3.87C14.3 3.95 14.51 3.99 14.72 3.99L14.73 3.99C15.38 3.99 15.96 3.59 16.21 2.99C16.45 2.39 16.31 1.7 15.84 1.24C15.38 0.78 14.69 0.65 14.09 0.9C13.49 1.16 13.1 1.75 13.11 2.4L13.11 2.4Z" /></g>
                                        <g><path fill="#1381a2" fill-rule="evenodd" class="shp0" d="M5.99 3.69C6.29 3.69 6.59 3.75 6.87 3.87C7.16 3.99 7.41 4.16 7.63 4.38C7.84 4.59 8.01 4.85 8.12 5.13C8.24 5.41 8.3 5.72 8.3 6.02C8.3 6.33 8.23 6.63 8.12 6.92C8 7.2 7.83 7.46 7.61 7.67C7.39 7.89 7.13 8.06 6.85 8.18C6.57 8.3 6.26 8.36 5.95 8.36L5.93 8.36C5.63 8.36 5.32 8.3 5.04 8.18C4.76 8.06 4.51 7.89 4.29 7.67C4.08 7.46 3.91 7.2 3.79 6.92C3.68 6.64 3.62 6.33 3.62 6.03C3.62 5.72 3.68 5.42 3.8 5.14C3.92 4.85 4.09 4.59 4.31 4.38C4.53 4.16 4.79 3.99 5.07 3.87C5.35 3.75 5.66 3.69 5.96 3.69L5.99 3.69ZM7.09 7.15C7.24 7.01 7.36 6.83 7.44 6.64C7.52 6.44 7.56 6.23 7.56 6.02C7.56 5.81 7.52 5.6 7.44 5.41C7.36 5.22 7.25 5.04 7.1 4.89C6.87 4.67 6.59 4.52 6.27 4.46C5.96 4.4 5.64 4.43 5.35 4.55C5.06 4.67 4.81 4.88 4.63 5.14C4.46 5.41 4.36 5.72 4.36 6.03C4.36 6.14 4.37 6.24 4.39 6.34C4.41 6.45 4.44 6.55 4.48 6.64C4.52 6.74 4.57 6.83 4.63 6.92C4.69 7.01 4.75 7.09 4.83 7.16C4.98 7.31 5.15 7.43 5.35 7.51C5.54 7.59 5.75 7.63 5.96 7.63C6.17 7.63 6.38 7.58 6.57 7.5C6.77 7.42 6.94 7.3 7.09 7.15L7.09 7.15Z" /></g>
                                        <g><path fill-rule="evenodd" class="shp0" d="M0 14.77C0 14.46 0.06 14.16 0.18 13.88C0.3 13.59 0.47 13.34 0.68 13.12C0.9 12.9 1.16 12.73 1.44 12.62C1.72 12.5 2.03 12.44 2.33 12.44C3.28 12.44 4.12 13.01 4.48 13.88C4.84 14.75 4.65 15.75 3.98 16.42C3.32 17.08 2.32 17.28 1.45 16.93C0.58 16.57 0 15.73 0 14.78L0 14.77ZM3.93 14.77C3.92 13.89 3.21 13.18 2.33 13.18C1.45 13.19 0.74 13.9 0.74 14.78C0.74 15.66 1.45 16.37 2.33 16.37C3.21 16.38 3.92 15.66 3.93 14.78L3.93 14.77Z" /></g>
                                        <g><path fill-rule="evenodd" class="shp0" d="M3.62 23.54L3.62 23.52C3.62 23.22 3.68 22.92 3.8 22.63C3.92 22.35 4.09 22.1 4.31 21.88C4.52 21.67 4.78 21.5 5.06 21.38C5.34 21.27 5.64 21.21 5.95 21.21L5.96 21.21C6.26 21.21 6.57 21.27 6.85 21.39C7.13 21.51 7.39 21.68 7.61 21.9C7.83 22.11 8 22.37 8.12 22.65C8.24 22.94 8.3 23.24 8.3 23.55L8.3 23.56C8.3 23.87 8.24 24.17 8.12 24.45C8 24.73 7.83 24.99 7.61 25.2C7.4 25.42 7.14 25.59 6.86 25.71C6.58 25.82 6.28 25.88 5.97 25.88L5.97 25.88C5.34 25.88 4.75 25.63 4.31 25.19C3.87 24.76 3.62 24.16 3.62 23.54ZM4.82 22.41C4.75 22.48 4.68 22.56 4.62 22.65C4.56 22.74 4.51 22.83 4.47 22.93C4.43 23.02 4.4 23.12 4.38 23.23C4.36 23.33 4.35 23.43 4.35 23.54C4.35 23.85 4.45 24.16 4.63 24.43C4.8 24.69 5.05 24.9 5.34 25.02C5.64 25.14 5.96 25.17 6.27 25.11C6.58 25.05 6.87 24.9 7.09 24.68C7.17 24.61 7.23 24.53 7.29 24.44C7.35 24.35 7.4 24.26 7.44 24.16C7.48 24.07 7.51 23.97 7.53 23.86C7.55 23.76 7.56 23.66 7.56 23.55C7.55 22.66 6.83 21.94 5.94 21.94C5.84 21.94 5.74 21.95 5.64 21.97C5.53 21.99 5.43 22.02 5.34 22.06C5.24 22.1 5.15 22.15 5.06 22.21C4.98 22.27 4.9 22.33 4.82 22.41L4.82 22.41Z" /></g>
                                        <g><path fill-rule="evenodd" class="shp0" d="M17.06 27.17C17.06 27.48 17 27.78 16.88 28.06C16.76 28.35 16.59 28.6 16.37 28.82C16.15 29.04 15.89 29.21 15.61 29.33C15.33 29.44 15.02 29.5 14.72 29.5L14.71 29.5C13.76 29.5 12.91 28.92 12.55 28.05C12.19 27.17 12.4 26.16 13.07 25.5C13.74 24.83 14.75 24.63 15.62 25C16.5 25.37 17.06 26.22 17.06 27.17ZM13.11 27.17C13.11 27.38 13.15 27.59 13.23 27.78C13.31 27.98 13.43 28.15 13.58 28.3C13.73 28.45 13.91 28.57 14.1 28.65C14.3 28.73 14.51 28.77 14.72 28.77L14.73 28.77C15.38 28.76 15.96 28.37 16.21 27.76C16.45 27.16 16.31 26.47 15.85 26.01C15.38 25.56 14.69 25.42 14.09 25.68C13.49 25.93 13.1 26.52 13.11 27.17L13.11 27.17Z" /></g>
                                        <g><path fill-rule="evenodd" class="shp0" d="M23.49 21.21C23.64 21.21 23.8 21.22 23.95 21.25C24.1 21.28 24.24 21.32 24.38 21.38C24.52 21.44 24.66 21.51 24.79 21.6C24.91 21.68 25.03 21.78 25.14 21.89C25.46 22.22 25.68 22.63 25.77 23.09C25.86 23.54 25.82 24.01 25.64 24.44C25.46 24.86 25.16 25.23 24.78 25.49C24.39 25.74 23.94 25.88 23.48 25.88L23.45 25.88C23.14 25.88 22.84 25.82 22.56 25.7C22.28 25.58 22.03 25.41 21.81 25.19C21.6 24.98 21.43 24.72 21.31 24.44C21.2 24.16 21.14 23.85 21.14 23.55C21.14 23.24 21.2 22.94 21.32 22.65C21.44 22.37 21.61 22.11 21.83 21.89C22.05 21.68 22.3 21.5 22.59 21.39C22.87 21.27 23.18 21.21 23.48 21.21L23.49 21.21ZM24.61 22.41C24.39 22.19 24.1 22.04 23.79 21.98C23.48 21.92 23.16 21.95 22.87 22.07C22.57 22.19 22.32 22.4 22.15 22.66C21.97 22.93 21.88 23.24 21.88 23.55C21.88 23.66 21.89 23.76 21.91 23.86C21.93 23.97 21.96 24.07 22 24.16C22.03 24.26 22.08 24.35 22.14 24.44C22.2 24.53 22.27 24.61 22.34 24.68C22.57 24.91 22.85 25.06 23.16 25.12C23.47 25.18 23.8 25.14 24.09 25.02C24.38 24.9 24.63 24.69 24.81 24.43C24.98 24.16 25.08 23.85 25.08 23.54C25.08 23.12 24.91 22.71 24.61 22.41Z" /></g>
                                        <g><path fill-rule="evenodd" class="shp0" d="M27.1 12.44C27.41 12.44 27.72 12.5 28 12.62C28.28 12.74 28.54 12.91 28.75 13.13C28.97 13.35 29.14 13.6 29.26 13.89C29.38 14.17 29.44 14.47 29.44 14.78L29.44 14.79C29.43 15.74 28.86 16.59 27.98 16.95C27.1 17.31 26.1 17.1 25.43 16.43C24.76 15.76 24.57 14.75 24.93 13.88C25.3 13 26.16 12.43 27.1 12.44ZM27.11 16.39C27.32 16.39 27.52 16.35 27.72 16.26C27.91 16.18 28.09 16.06 28.24 15.91C28.38 15.77 28.5 15.59 28.58 15.39C28.66 15.2 28.7 14.99 28.7 14.78L28.7 14.77C28.7 14.12 28.3 13.54 27.7 13.29C27.1 13.05 26.41 13.19 25.95 13.65C25.49 14.12 25.36 14.81 25.61 15.41C25.87 16.01 26.45 16.39 27.1 16.39L27.11 16.39Z" /></g>
                                        <g><path fill-rule="evenodd" class="shp0" d="M23.48 3.69C23.78 3.69 24.09 3.75 24.37 3.87C24.65 3.99 24.91 4.16 25.13 4.38C25.35 4.59 25.52 4.85 25.64 5.14C25.76 5.42 25.82 5.72 25.82 6.03L25.82 6.05C25.82 6.35 25.76 6.65 25.64 6.93C25.52 7.22 25.35 7.47 25.13 7.69C24.92 7.9 24.66 8.07 24.38 8.19C24.1 8.3 23.8 8.36 23.49 8.36L23.48 8.36C23.18 8.36 22.87 8.3 22.59 8.18C22.31 8.06 22.05 7.89 21.83 7.67C21.61 7.46 21.44 7.2 21.32 6.92C21.21 6.63 21.14 6.33 21.14 6.02L21.14 6C21.14 5.7 21.2 5.4 21.32 5.12C21.44 4.84 21.61 4.58 21.83 4.37C22.04 4.15 22.3 3.98 22.58 3.86C22.86 3.75 23.16 3.69 23.47 3.69L23.48 3.69ZM24.61 7.16C24.69 7.09 24.75 7.01 24.81 6.92C24.87 6.83 24.92 6.74 24.96 6.64C25 6.55 25.03 6.45 25.05 6.34C25.07 6.24 25.08 6.14 25.08 6.03C25.07 5.14 24.35 4.43 23.47 4.43L23.45 4.43C23.24 4.43 23.04 4.47 22.84 4.55C22.65 4.63 22.48 4.75 22.33 4.9C22.19 5.04 22.07 5.22 21.99 5.41C21.91 5.6 21.87 5.81 21.88 6.02C21.88 6.34 21.97 6.65 22.15 6.91C22.32 7.17 22.57 7.38 22.87 7.5C23.16 7.62 23.48 7.66 23.79 7.6C24.1 7.54 24.39 7.38 24.62 7.16L24.61 7.16Z" /></g>
                                        <g><path class="shp0" d="M20.56 4.28C20.65 4.32 20.74 4.33 20.83 4.3C20.92 4.27 20.99 4.21 21.04 4.13C21.08 4.05 21.1 3.95 21.08 3.86C21.05 3.77 21 3.69 20.92 3.64C20.72 3.53 20.51 3.42 20.31 3.32C20.1 3.22 19.89 3.12 19.67 3.03C19.46 2.94 19.24 2.86 19.02 2.78C18.81 2.7 18.58 2.63 18.36 2.56C18.27 2.54 18.18 2.55 18.09 2.59C18.01 2.64 17.95 2.71 17.92 2.8C17.89 2.89 17.89 2.99 17.93 3.07C17.97 3.16 18.04 3.22 18.13 3.26L18.15 3.27C18.99 3.51 19.8 3.86 20.56 4.28L20.56 4.28Z" /></g>
                                        <g><path class="shp0" d="M8.86 4.24C8.92 4.24 8.98 4.23 9.03 4.2C9.22 4.09 9.42 3.99 9.62 3.9C9.81 3.81 10.01 3.72 10.22 3.64C10.42 3.55 10.62 3.48 10.83 3.41C11.04 3.34 11.25 3.27 11.46 3.21C11.55 3.18 11.63 3.12 11.68 3.04C11.72 2.95 11.74 2.85 11.71 2.76C11.68 2.66 11.62 2.58 11.53 2.54C11.45 2.49 11.35 2.48 11.26 2.5C10.36 2.76 9.5 3.11 8.68 3.55C8.61 3.59 8.55 3.65 8.52 3.73C8.49 3.8 8.48 3.88 8.5 3.96C8.52 4.04 8.57 4.11 8.63 4.16C8.7 4.21 8.78 4.24 8.86 4.24L8.86 4.24Z" /></g>
                                        <g>
                                            <path class="shp0" d="M2.54 11.09C2.52 11.15 2.52 11.21 2.53 11.26C2.54 11.32 2.56 11.37 2.6 11.42C2.63 11.47 2.67 11.5 2.72 11.53C2.78 11.55 2.83 11.57 2.89 11.57C2.93 11.57 2.97 11.56 3.01 11.55C3.04 11.54 3.08 11.52 3.11 11.49C3.14 11.47 3.17 11.44 3.19 11.41C3.21 11.38 3.23 11.34 3.24 11.3C3.49 10.47 3.83 9.66 4.26 8.89C4.32 8.81 4.34 8.7 4.31 8.6C4.29 8.5 4.22 8.42 4.13 8.37C4.05 8.32 3.94 8.31 3.84 8.34C3.74 8.37 3.66 8.44 3.62 8.53C3.51 8.74 3.4 8.94 3.3 9.15C3.2 9.36 3.1 9.57 3.01 9.78C2.92 10 2.83 10.21 2.75 10.43C2.68 10.65 2.6 10.87 2.54 11.09L2.54 11.09Z" />
                                        </g>
                                        <g><path class="shp0" d="M3.19 18C3.16 17.91 3.1 17.83 3.01 17.79C2.93 17.74 2.83 17.73 2.74 17.76C2.65 17.78 2.57 17.84 2.52 17.93C2.47 18.01 2.46 18.11 2.48 18.2C2.54 18.42 2.61 18.64 2.69 18.86C2.76 19.08 2.85 19.3 2.93 19.52C3.02 19.73 3.11 19.94 3.21 20.15C3.31 20.36 3.42 20.57 3.53 20.78C3.57 20.86 3.65 20.92 3.75 20.95C3.84 20.98 3.94 20.97 4.03 20.92C4.11 20.88 4.18 20.8 4.2 20.7C4.23 20.61 4.22 20.51 4.17 20.42C4.07 20.23 3.97 20.04 3.88 19.84C3.78 19.64 3.7 19.44 3.61 19.24C3.53 19.04 3.46 18.83 3.38 18.62C3.31 18.42 3.25 18.21 3.19 18L3.19 18Z" /></g>
                                        <g><path class="shp0" d="M11.07 26.92C11.16 26.94 11.26 26.93 11.34 26.88C11.42 26.83 11.48 26.76 11.51 26.67C11.54 26.58 11.53 26.48 11.49 26.4C11.44 26.31 11.37 26.25 11.28 26.22C11.07 26.15 10.87 26.08 10.66 26.01C10.45 25.94 10.25 25.86 10.05 25.77C9.85 25.69 9.65 25.6 9.45 25.5C9.26 25.4 9.06 25.3 8.87 25.2C8.79 25.14 8.68 25.12 8.58 25.14C8.48 25.17 8.39 25.23 8.34 25.32C8.29 25.41 8.28 25.52 8.31 25.62C8.35 25.71 8.42 25.79 8.51 25.84C8.72 25.95 8.92 26.06 9.13 26.16C9.34 26.26 9.55 26.36 9.76 26.45C9.98 26.54 10.19 26.62 10.41 26.7C10.63 26.78 10.85 26.85 11.07 26.92L11.07 26.92Z" /></g>
                                        <g><path class="shp0" d="M18.08 26.99C18.09 26.99 18.1 26.99 18.1 26.99C18.11 26.99 18.12 26.99 18.13 26.99C18.14 26.98 18.15 26.98 18.15 26.98C18.16 26.98 18.17 26.98 18.18 26.98C18.4 26.91 18.62 26.84 18.84 26.77C19.06 26.69 19.28 26.61 19.5 26.52C19.71 26.44 19.92 26.34 20.13 26.24C20.34 26.14 20.55 26.04 20.75 25.93C20.84 25.88 20.9 25.8 20.93 25.71C20.96 25.62 20.95 25.52 20.9 25.43C20.86 25.34 20.78 25.28 20.68 25.25C20.59 25.22 20.49 25.23 20.4 25.28C20.21 25.39 20.02 25.48 19.82 25.58C19.62 25.67 19.42 25.76 19.22 25.84C19.02 25.92 18.81 26 18.6 26.07C18.4 26.14 18.19 26.21 17.98 26.27C17.89 26.29 17.82 26.35 17.78 26.42C17.73 26.5 17.71 26.58 17.72 26.67C17.73 26.76 17.78 26.84 17.84 26.9C17.91 26.95 17.99 26.99 18.08 26.99L18.08 26.99Z" /></g>
                                        <g><path fill="#1381a2" class="shp0" d="M26.65 17.93C26.6 17.91 26.56 17.91 26.51 17.91C26.46 17.92 26.41 17.93 26.37 17.95C26.33 17.98 26.29 18.01 26.26 18.05C26.23 18.08 26.21 18.13 26.19 18.17C26.13 18.38 26.06 18.59 25.99 18.8C25.92 19 25.84 19.21 25.75 19.41C25.67 19.61 25.58 19.81 25.48 20C25.38 20.2 25.28 20.39 25.18 20.59C25.14 20.67 25.14 20.76 25.17 20.85C25.2 20.93 25.26 21.01 25.34 21.05C25.41 21.1 25.51 21.11 25.6 21.09C25.68 21.07 25.76 21.02 25.82 20.94C25.93 20.74 26.04 20.53 26.14 20.33C26.24 20.12 26.34 19.91 26.43 19.69C26.52 19.48 26.6 19.26 26.68 19.04C26.76 18.83 26.83 18.6 26.9 18.38C26.91 18.34 26.91 18.29 26.91 18.24C26.9 18.19 26.89 18.15 26.87 18.1C26.84 18.06 26.81 18.02 26.78 17.99C26.74 17.96 26.7 17.94 26.65 17.93L26.65 17.93Z" /></g>
                                        <g><path class="shp0" d="M25.26 9.05C25.36 9.24 25.46 9.44 25.56 9.64C25.65 9.83 25.74 10.04 25.82 10.24C25.9 10.44 25.98 10.65 26.05 10.85C26.12 11.06 26.19 11.27 26.25 11.48C26.27 11.57 26.34 11.65 26.42 11.7C26.51 11.75 26.61 11.76 26.7 11.73C26.79 11.7 26.87 11.64 26.92 11.56C26.97 11.47 26.98 11.37 26.95 11.28C26.89 11.05 26.82 10.83 26.75 10.61C26.67 10.39 26.59 10.18 26.5 9.96C26.41 9.75 26.32 9.53 26.22 9.32C26.12 9.11 26.02 8.91 25.91 8.7C25.86 8.62 25.78 8.55 25.69 8.52C25.6 8.5 25.49 8.51 25.41 8.55C25.32 8.6 25.26 8.68 25.23 8.77C25.2 8.87 25.21 8.97 25.26 9.05L25.26 9.05Z" /></g>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="/#!how-it-works">
                            <span>how it works</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="30"
                                 height="30"
                                 viewBox="0 0 30 30">
                                <g id="Group_6329"
                                   data-name="Group 6329"
                                   transform="translate(-524.037 -720)">
                                    <path id="Path_6803"
                                          data-name="Path 6803"
                                          d="M25.548,5.388a.376.376,0,0,0,.278.122.376.376,0,0,0,.277-.628c-.259-.284-.534-.564-.816-.829a.375.375,0,0,0-.514.547C25.041,4.852,25.3,5.117,25.548,5.388Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6804"
                                          data-name="Path 6804"
                                          d="M27.531,6.716a.376.376,0,1,0-.626.415c.2.306.395.624.573.944a.377.377,0,0,0,.329.193.375.375,0,0,0,.327-.557C27.947,7.374,27.744,7.04,27.531,6.716Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6805"
                                          data-name="Path 6805"
                                          d="M29.458,10.932c-.1-.372-.224-.744-.357-1.108a.375.375,0,0,0-.7.258c.126.346.241.7.339,1.052a.377.377,0,0,0,.362.274.357.357,0,0,0,.1-.014A.375.375,0,0,0,29.458,10.932Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6806"
                                          data-name="Path 6806"
                                          d="M29.586,16.281a.373.373,0,0,0-.415.33c-.042.363-.1.731-.167,1.093a.375.375,0,0,0,.3.439.342.342,0,0,0,.071.007.376.376,0,0,0,.368-.305c.073-.38.132-.767.176-1.15A.375.375,0,0,0,29.586,16.281Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6807"
                                          data-name="Path 6807"
                                          d="M28.19,21.354a.366.366,0,0,0,.154.034.375.375,0,0,0,.342-.221c.16-.353.307-.716.438-1.079a.375.375,0,0,0-.706-.255c-.124.345-.264.69-.416,1.025A.374.374,0,0,0,28.19,21.354Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6808"
                                          data-name="Path 6808"
                                          d="M4.289,4.481c-.27.276-.533.565-.782.862a.375.375,0,0,0,.288.616.371.371,0,0,0,.287-.134c.236-.281.487-.557.743-.819a.375.375,0,0,0-.536-.525Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6809"
                                          data-name="Path 6809"
                                          d="M26.365,24.222a.376.376,0,0,0,.527-.068c.237-.307.464-.626.675-.948a.375.375,0,1,0-.628-.411c-.2.305-.416.608-.641.9A.376.376,0,0,0,26.365,24.222Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6810"
                                          data-name="Path 6810"
                                          d="M21.02,28.34a.376.376,0,0,0,.338.212.363.363,0,0,0,.163-.037c.346-.166.691-.35,1.028-.546a.376.376,0,0,0-.378-.649c-.319.186-.648.361-.976.519A.376.376,0,0,0,21.02,28.34Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6811"
                                          data-name="Path 6811"
                                          d="M17.783,29.366a.379.379,0,0,0,.447.287c.378-.083.757-.182,1.126-.293a.376.376,0,0,0-.217-.719c-.351.106-.711.2-1.07.279A.374.374,0,0,0,17.783,29.366Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6812"
                                          data-name="Path 6812"
                                          d="M22.492,1.975a.375.375,0,0,0-.376.65c.315.182.63.38.937.589a.375.375,0,0,0,.423-.62C23.154,2.374,22.823,2.166,22.492,1.975Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6813"
                                          data-name="Path 6813"
                                          d="M8.406,27.632a.375.375,0,1,0-.348.665c.341.179.7.347,1.052.5a.383.383,0,0,0,.147.03.376.376,0,0,0,.148-.721C9.066,27.962,8.73,27.8,8.406,27.632Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6814"
                                          data-name="Path 6814"
                                          d="M14.776,29.248h-.005a.375.375,0,0,0-.005.75l.241,0c.308,0,.618-.01.921-.028a.375.375,0,0,0-.045-.749c-.289.017-.584.026-.876.027Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6815"
                                          data-name="Path 6815"
                                          d="M11.041,29.086a.375.375,0,0,0,.272.456c.372.094.755.175,1.138.241a.352.352,0,0,0,.064,0,.375.375,0,0,0,.063-.745c-.364-.062-.728-.14-1.081-.229A.375.375,0,0,0,11.041,29.086Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6816"
                                          data-name="Path 6816"
                                          d="M5.671,25.767a.375.375,0,0,0-.491.567c.291.253.6.5.907.728a.376.376,0,0,0,.447-.6C6.239,26.24,5.948,26.007,5.671,25.767Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6817"
                                          data-name="Path 6817"
                                          d="M20.393.973c-.36-.138-.73-.264-1.1-.375a.375.375,0,1,0-.214.719c.353.105.7.225,1.046.357a.379.379,0,0,0,.135.025.376.376,0,0,0,.135-.726Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6818"
                                          data-name="Path 6818"
                                          d="M.044,13.826C.015,14.211,0,14.6,0,14.988v.063a.376.376,0,1,0,.751,0v-.063c0-.367.014-.739.042-1.1a.376.376,0,1,0-.749-.057Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6819"
                                          data-name="Path 6819"
                                          d="M.781,18.8a.385.385,0,0,0,.088-.011.375.375,0,0,0,.277-.452c-.086-.357-.159-.721-.217-1.084a.375.375,0,0,0-.741.118c.061.382.138.766.228,1.141A.376.376,0,0,0,.781,18.8Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6820"
                                          data-name="Path 6820"
                                          d="M17.019.11C16.638.059,16.248.022,15.861,0a.38.38,0,0,0-.4.354.376.376,0,0,0,.354.4c.367.02.738.055,1.1.1a.289.289,0,0,0,.05,0,.376.376,0,0,0,.05-.748Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6821"
                                          data-name="Path 6821"
                                          d="M7,2.292c-.32.2-.637.42-.942.647H2.984a.376.376,0,1,0,0,.751H5.877V6.585a.376.376,0,0,0,.751,0V3.452c.251-.183.509-.361.77-.526A.375.375,0,1,0,7,2.292Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6822"
                                          data-name="Path 6822"
                                          d="M1.621,21.779a.375.375,0,1,0,.669-.34c-.166-.327-.322-.664-.462-1a.375.375,0,1,0-.693.287C1.282,21.08,1.446,21.435,1.621,21.779Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6823"
                                          data-name="Path 6823"
                                          d="M29.492,12.884a.376.376,0,0,0-.329.417c.043.363.072.734.087,1.1a.375.375,0,0,0,.375.36h.015a.375.375,0,0,0,.36-.39c-.015-.388-.046-.778-.091-1.16A.377.377,0,0,0,29.492,12.884Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6824"
                                          data-name="Path 6824"
                                          d="M3.546,24.68a.375.375,0,1,0,.573-.485c-.237-.28-.466-.573-.68-.87a.375.375,0,1,0-.609.439C3.056,24.077,3.3,24.385,3.546,24.68Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6825"
                                          data-name="Path 6825"
                                          d="M.68,11.98a.367.367,0,0,0,.086.01.376.376,0,0,0,.365-.289c.085-.358.184-.716.3-1.065a.375.375,0,1,0-.715-.229c-.118.367-.222.745-.311,1.121A.375.375,0,0,0,.68,11.98Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6826"
                                          data-name="Path 6826"
                                          d="M12.516.944c.362-.064.73-.114,1.1-.149a.375.375,0,0,0-.072-.747c-.384.037-.772.09-1.153.157a.375.375,0,0,0,.065.745A.366.366,0,0,0,12.516.944Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6827"
                                          data-name="Path 6827"
                                          d="M10.135.786c-.363.124-.728.265-1.084.419a.375.375,0,1,0,.3.689c.338-.146.684-.28,1.029-.4a.375.375,0,1,0-.243-.71Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6828"
                                          data-name="Path 6828"
                                          d="M2.671,7.108a.374.374,0,0,0-.515.127c-.2.331-.39.673-.564,1.018a.376.376,0,0,0,.167.5.364.364,0,0,0,.168.04.375.375,0,0,0,.336-.206c.165-.328.345-.654.535-.968A.375.375,0,0,0,2.671,7.108Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6829"
                                          data-name="Path 6829"
                                          d="M23.713,23.119a.375.375,0,0,0-.375.376v3.27a.375.375,0,0,0,.375.375h3.269a.375.375,0,0,0,0-.75H24.764c.194-.167.386-.336.569-.51a.375.375,0,0,0-.516-.545c-.233.221-.48.433-.729.64V23.5A.375.375,0,0,0,23.713,23.119Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6830"
                                          data-name="Path 6830"
                                          d="M15.019,17.343a2.335,2.335,0,1,0-1.652-.684A2.325,2.325,0,0,0,15.019,17.343ZM13.9,13.885a1.585,1.585,0,1,1-.465,1.122A1.58,1.58,0,0,1,13.9,13.885Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6831"
                                          data-name="Path 6831"
                                          d="M10.721,11.79a.365.365,0,0,0-.3-.079.377.377,0,0,0-.256.166,5.74,5.74,0,0,0-.791,1.913.375.375,0,0,0,.327.452.769.769,0,0,1,0,1.529.375.375,0,0,0-.328.452,5.731,5.731,0,0,0,.791,1.913.376.376,0,0,0,.552.088.768.768,0,0,1,1.08,1.081.376.376,0,0,0,.088.552,5.735,5.735,0,0,0,1.912.791.382.382,0,0,0,.3-.063.374.374,0,0,0,.153-.265.768.768,0,0,1,1.528,0,.378.378,0,0,0,.154.265.37.37,0,0,0,.22.071.413.413,0,0,0,.078-.008,5.726,5.726,0,0,0,1.912-.791.377.377,0,0,0,.089-.552.768.768,0,0,1,1.08-1.081.375.375,0,0,0,.551-.088,5.714,5.714,0,0,0,.791-1.913.375.375,0,0,0-.327-.452.769.769,0,0,1,0-1.529.375.375,0,0,0,.327-.452,5.731,5.731,0,0,0-.791-1.913.375.375,0,0,0-.256-.166.366.366,0,0,0-.3.079.769.769,0,0,1-1.08-1.082.377.377,0,0,0-.088-.551,5.731,5.731,0,0,0-1.913-.791.368.368,0,0,0-.3.063.374.374,0,0,0-.154.264.768.768,0,0,1-1.528,0,.375.375,0,0,0-.452-.327,5.726,5.726,0,0,0-1.912.791.375.375,0,0,0-.088.551.769.769,0,0,1-1.081,1.082Zm1.557.475a1.515,1.515,0,0,0,.328-1.661,4.919,4.919,0,0,1,1.006-.417,1.52,1.52,0,0,0,2.812,0,4.927,4.927,0,0,1,1.007.417,1.52,1.52,0,0,0,1.989,1.989,5,5,0,0,1,.416,1.007,1.521,1.521,0,0,0,0,2.813,4.981,4.981,0,0,1-.416,1.007,1.521,1.521,0,0,0-1.989,1.99,4.924,4.924,0,0,1-1.007.416,1.52,1.52,0,0,0-2.812,0,4.939,4.939,0,0,1-1.006-.416,1.521,1.521,0,0,0-1.989-1.99,4.883,4.883,0,0,1-.416-1.006,1.521,1.521,0,0,0,0-2.814,4.951,4.951,0,0,1,.417-1.007A1.514,1.514,0,0,0,12.278,12.265Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6832"
                                          data-name="Path 6832"
                                          d="M18.3,22.823a.366.366,0,0,0,.151-.032,8.425,8.425,0,0,0,1.366-.756.376.376,0,0,0-.423-.621,7.673,7.673,0,0,1-1.244.69.375.375,0,0,0,.15.719Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6833"
                                          data-name="Path 6833"
                                          d="M23.109,15.786h.018a.374.374,0,0,0,.374-.357c.006-.131.01-.263.01-.394a8.42,8.42,0,0,0-.081-1.166.375.375,0,1,0-.743.1,7.878,7.878,0,0,1,.073,1.063c0,.12,0,.239-.008.359A.375.375,0,0,0,23.109,15.786Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6834"
                                          data-name="Path 6834"
                                          d="M21.789,11.284a7.631,7.631,0,0,1,.574,1.3.375.375,0,0,0,.356.256.384.384,0,0,0,.119-.019.376.376,0,0,0,.237-.475,8.429,8.429,0,0,0-.63-1.429.375.375,0,0,0-.656.365Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6835"
                                          data-name="Path 6835"
                                          d="M21.412,19.4a7.869,7.869,0,0,1-.915,1.1l.252.278.282.248a8.458,8.458,0,0,0,1-1.2.375.375,0,1,0-.618-.425Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6836"
                                          data-name="Path 6836"
                                          d="M21.031,9.049c-.183-.184-.376-.359-.571-.522a.375.375,0,1,0-.481.576c.179.149.354.309.522.477s.331.347.485.531a.374.374,0,0,0,.288.135.37.37,0,0,0,.24-.087.376.376,0,0,0,.049-.528C21.4,9.429,21.216,9.233,21.031,9.049Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6837"
                                          data-name="Path 6837"
                                          d="M10.274,8.043a8.643,8.643,0,0,0-1.2,1,.375.375,0,0,0,.53.532,7.766,7.766,0,0,1,1.1-.909.376.376,0,0,0-.424-.62Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6838"
                                          data-name="Path 6838"
                                          d="M22.556,16.8a7.757,7.757,0,0,1-.452,1.35.376.376,0,0,0,.192.5.387.387,0,0,0,.151.032.375.375,0,0,0,.344-.224,8.449,8.449,0,0,0,.5-1.481.375.375,0,0,0-.731-.172Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6839"
                                          data-name="Path 6839"
                                          d="M7.79,18.631a.377.377,0,0,0-.146.511,8.492,8.492,0,0,0,.882,1.289.376.376,0,0,0,.578-.48,7.546,7.546,0,0,1-.8-1.174A.376.376,0,0,0,7.79,18.631Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6840"
                                          data-name="Path 6840"
                                          d="M6.984,15.767a.376.376,0,0,0-.321.423,8.589,8.589,0,0,0,.353,1.521.376.376,0,0,0,.713-.237,7.621,7.621,0,0,1-.322-1.386A.377.377,0,0,0,6.984,15.767Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6841"
                                          data-name="Path 6841"
                                          d="M7.263,12.8a.377.377,0,0,0-.451.28,8.48,8.48,0,0,0-.218,1.546.376.376,0,0,0,.357.393h.018a.376.376,0,0,0,.375-.357,7.786,7.786,0,0,1,.2-1.41A.375.375,0,0,0,7.263,12.8Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6842"
                                          data-name="Path 6842"
                                          d="M11.445,7.779A.376.376,0,0,0,11.789,8a.383.383,0,0,0,.151-.032,7.722,7.722,0,0,1,1.35-.45.375.375,0,0,0-.17-.73,8.476,8.476,0,0,0-1.482.493A.375.375,0,0,0,11.445,7.779Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6843"
                                          data-name="Path 6843"
                                          d="M15.417,23.493a8.467,8.467,0,0,0,1.547-.21.376.376,0,0,0-.169-.732,7.689,7.689,0,0,1-1.411.192.376.376,0,0,0,.016.751Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6844"
                                          data-name="Path 6844"
                                          d="M17.27,7.246a.374.374,0,0,0,.236.475,7.719,7.719,0,0,1,1.3.576.375.375,0,0,0,.366-.655,8.442,8.442,0,0,0-1.429-.633A.376.376,0,0,0,17.27,7.246Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6845"
                                          data-name="Path 6845"
                                          d="M13.858,23.418l.053,0a.375.375,0,0,0,.052-.747,7.813,7.813,0,0,1-1.385-.327.375.375,0,1,0-.239.711A8.564,8.564,0,0,0,13.858,23.418Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6846"
                                          data-name="Path 6846"
                                          d="M14.308,6.97a.373.373,0,0,0,.392.358,7.858,7.858,0,0,1,1.422.067.455.455,0,0,0,.052,0,.375.375,0,0,0,.052-.747,8.48,8.48,0,0,0-1.56-.074A.376.376,0,0,0,14.308,6.97Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6847"
                                          data-name="Path 6847"
                                          d="M11.278,21.768a7.577,7.577,0,0,1-1.171-.808.376.376,0,0,0-.481.577,8.425,8.425,0,0,0,1.286.887.376.376,0,1,0,.366-.656Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                    <path id="Path_6848"
                                          data-name="Path 6848"
                                          d="M8.072,10.24A8.468,8.468,0,0,0,7.309,11.6a.375.375,0,0,0,.686.3,7.72,7.72,0,0,1,.7-1.242.375.375,0,0,0-.619-.425Z"
                                          transform="translate(524.037 720)"
                                          fill="#1381a2" />
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="/#!tools">
                            <span>tools</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="26"
                                 height="26"
                                 viewBox="0 0 26 26">
                                <g id="Group_6330"
                                   data-name="Group 6330"
                                   transform="translate(-646.406 -721)">
                                    <path id="Path_6849"
                                          data-name="Path 6849"
                                          d="M15.842,7.76a2.4,2.4,0,1,0,2.4,2.4A2.4,2.4,0,0,0,15.842,7.76Zm0,4.146a1.748,1.748,0,1,1,1.748-1.748A1.75,1.75,0,0,1,15.842,11.906Z"
                                          transform="translate(646.406 721)"
                                          fill="#1381a2" />
                                    <path id="Path_6850"
                                          data-name="Path 6850"
                                          d="M20.563,9.471a.327.327,0,0,0,.229-.133.322.322,0,0,0,.054-.259,5.059,5.059,0,0,0-.7-1.7.326.326,0,0,0-.478-.076.691.691,0,0,1-.972-.972.326.326,0,0,0-.076-.478,5.059,5.059,0,0,0-1.7-.7.322.322,0,0,0-.259.054.327.327,0,0,0-.133.229.691.691,0,0,1-1.375,0,.323.323,0,0,0-.391-.284,5.064,5.064,0,0,0-1.7.7.326.326,0,0,0-.076.478.691.691,0,0,1-.972.972.32.32,0,0,0-.256-.068.325.325,0,0,0-.222.144,5.08,5.08,0,0,0-.7,1.7.325.325,0,0,0,.283.392.691.691,0,0,1,0,1.375.324.324,0,0,0-.284.392,5.089,5.089,0,0,0,.7,1.7.325.325,0,0,0,.222.144.32.32,0,0,0,.256-.068.691.691,0,0,1,.972.972.326.326,0,0,0,.076.478,5.085,5.085,0,0,0,1.7.7.323.323,0,0,0,.259-.054.331.331,0,0,0,.132-.23.691.691,0,0,1,1.375,0,.324.324,0,0,0,.324.29.321.321,0,0,0,.068-.007,5.08,5.08,0,0,0,1.7-.7.326.326,0,0,0,.076-.478.691.691,0,0,1,.972-.972.326.326,0,0,0,.478-.076,5.068,5.068,0,0,0,.7-1.7.325.325,0,0,0-.054-.26.329.329,0,0,0-.229-.132.691.691,0,0,1,0-1.375ZM20.135,11.4a4.43,4.43,0,0,1-.378.909A1.343,1.343,0,0,0,18,14.074a4.371,4.371,0,0,1-.909.377,1.343,1.343,0,0,0-2.491,0,4.371,4.371,0,0,1-.909-.377,1.343,1.343,0,0,0-1.761-1.761,4.371,4.371,0,0,1-.377-.909,1.343,1.343,0,0,0,0-2.491A4.371,4.371,0,0,1,11.926,8a1.343,1.343,0,0,0,1.761-1.761,4.371,4.371,0,0,1,.909-.377,1.343,1.343,0,0,0,2.491,0A4.371,4.371,0,0,1,18,6.243,1.343,1.343,0,0,0,19.757,8a4.43,4.43,0,0,1,.378.909,1.343,1.343,0,0,0,0,2.491Z"
                                          transform="translate(646.406 721)"
                                          fill="#1381a2" />
                                    <path id="Path_6851"
                                          data-name="Path 6851"
                                          d="M10.323,4.64a7.8,7.8,0,1,0,11.037,0A7.813,7.813,0,0,0,10.323,4.64ZM20.9,15.217A7.151,7.151,0,1,1,20.9,5.1,7.161,7.161,0,0,1,20.9,15.217Z"
                                          transform="translate(646.406 721)"
                                          fill="#1381a2" />
                                    <path id="Path_6852"
                                          data-name="Path 6852"
                                          d="M15.842,0A10.157,10.157,0,0,0,7.5,15.951L6.126,17.324a1.368,1.368,0,0,0-1.394.328L.748,21.635a2.558,2.558,0,0,0,3.617,3.617l3.984-3.984a1.367,1.367,0,0,0,.327-1.394L10.049,18.5A10.158,10.158,0,1,0,15.842,0ZM7.889,20.809,3.9,24.792a1.907,1.907,0,1,1-2.7-2.7l3.983-3.984a.722.722,0,0,1,1.018,0l1.681,1.68A.723.723,0,0,1,7.889,20.809Zm.435-1.5-1.63-1.63,1.2-1.2a9.934,9.934,0,0,0,1.631,1.631Zm14.241-2.424a9.508,9.508,0,1,1,0-13.447A9.52,9.52,0,0,1,22.565,16.882Z"
                                          transform="translate(646.406 721)"
                                          fill="#1381a2" />
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="/#!training">
                            <span>training</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="26.77"
                                 height="26"
                                 viewBox="0 0 26.77 26">
                                <g id="Group_6331"
                                   data-name="Group 6331"
                                   transform="translate(-780.287 -722)">
                                    <path id="Path_6776"
                                          data-name="Path 6776"
                                          d="M.325,26H18.47a.324.324,0,0,0,.325-.325V1.706a.324.324,0,0,0-.325-.325H16.156V.325a.325.325,0,0,0-.65,0V1.381H11.867V.325a.325.325,0,1,0-.65,0V1.381H7.578V.325a.325.325,0,0,0-.65,0V1.381H3.289V.325a.325.325,0,0,0-.65,0V1.381H.325A.324.324,0,0,0,0,1.706V25.675A.324.324,0,0,0,.325,26ZM.65,2.031H2.639v.944a1.416,1.416,0,1,0,.65,0V2.031H6.928v.944a1.417,1.417,0,1,0,.65,0V2.031h3.639v.944a1.417,1.417,0,1,0,.65,0V2.031h3.639v.944a1.417,1.417,0,1,0,.65,0V2.031h1.989V25.35H.65ZM2.964,3.584A.767.767,0,1,1,2.2,4.35.767.767,0,0,1,2.964,3.584Zm4.289,0a.767.767,0,1,1-.767.766A.768.768,0,0,1,7.253,3.584Zm4.289,0a.767.767,0,1,1-.766.766A.768.768,0,0,1,11.542,3.584Zm4.289,0a.767.767,0,1,1-.766.766A.768.768,0,0,1,15.831,3.584Z"
                                          transform="translate(780.287 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6777"
                                          data-name="Path 6777"
                                          d="M22.433,25.622a.29.29,0,0,0,.031.054.772.772,0,0,0,.63.324h0a.774.774,0,0,0,.63-.324.366.366,0,0,0,.032-.054l2.213-4.76A.326.326,0,0,0,26,20.725V1.784A1.757,1.757,0,0,0,24.275,0H21.913a1.757,1.757,0,0,0-1.725,1.784V20.725a.326.326,0,0,0,.031.137Zm1.885-2.75H21.87l-.913-1.964h4.274Zm-3.48-2.614V5.579H25.35V20.258ZM25.35,4.929H20.838V3.494H25.35ZM23.183,25.312a.123.123,0,0,1-.089.038h0A.121.121,0,0,1,23,25.312l-.832-1.79h1.843ZM21.913.65h2.362A1.106,1.106,0,0,1,25.35,1.784v1.06H20.838V1.784A1.107,1.107,0,0,1,21.913.65Z"
                                          transform="translate(781.057 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6778"
                                          data-name="Path 6778"
                                          d="M3.852,8.349H2.658a.325.325,0,1,0,0,.65H3.852a.325.325,0,1,0,0-.65Z"
                                          transform="translate(779.773 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6779"
                                          data-name="Path 6779"
                                          d="M6.923,8.349H5.729a.325.325,0,1,0,0,.65H6.923a.325.325,0,1,0,0-.65Z"
                                          transform="translate(779.967 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6780"
                                          data-name="Path 6780"
                                          d="M9.994,8.349H8.8A.325.325,0,0,0,8.8,9H9.994a.325.325,0,0,0,0-.65Z"
                                          transform="translate(780.16 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6781"
                                          data-name="Path 6781"
                                          d="M13.066,8.349H11.872a.325.325,0,1,0,0,.65h1.194a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.354 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6782"
                                          data-name="Path 6782"
                                          d="M16.137,8.349H14.943a.325.325,0,0,0,0,.65h1.194a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.547 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6783"
                                          data-name="Path 6783"
                                          d="M3.852,11.376H2.658a.325.325,0,1,0,0,.65H3.852a.325.325,0,1,0,0-.65Z"
                                          transform="translate(779.773 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6784"
                                          data-name="Path 6784"
                                          d="M6.923,11.376H5.729a.325.325,0,1,0,0,.65H6.923a.325.325,0,1,0,0-.65Z"
                                          transform="translate(779.967 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6785"
                                          data-name="Path 6785"
                                          d="M9.994,11.376H8.8a.325.325,0,0,0,0,.65H9.994a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.16 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6786"
                                          data-name="Path 6786"
                                          d="M13.066,11.376H11.872a.325.325,0,0,0,0,.65h1.194a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.354 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6787"
                                          data-name="Path 6787"
                                          d="M16.137,11.376H14.943a.325.325,0,0,0,0,.65h1.194a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.547 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6788"
                                          data-name="Path 6788"
                                          d="M3.852,14.4H2.658a.325.325,0,1,0,0,.65H3.852a.325.325,0,1,0,0-.65Z"
                                          transform="translate(779.773 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6789"
                                          data-name="Path 6789"
                                          d="M6.923,14.4H5.729a.325.325,0,1,0,0,.65H6.923a.325.325,0,1,0,0-.65Z"
                                          transform="translate(779.967 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6790"
                                          data-name="Path 6790"
                                          d="M9.994,14.4H8.8a.325.325,0,0,0,0,.65H9.994a.325.325,0,0,0,0-.65Z"
                                          transform="translate(780.16 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6791"
                                          data-name="Path 6791"
                                          d="M13.066,14.4H11.872a.325.325,0,0,0,0,.65h1.194a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.354 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6792"
                                          data-name="Path 6792"
                                          d="M16.137,14.4H14.943a.325.325,0,0,0,0,.65h1.194a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.547 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6793"
                                          data-name="Path 6793"
                                          d="M3.852,17.428H2.658a.325.325,0,1,0,0,.65H3.852a.325.325,0,1,0,0-.65Z"
                                          transform="translate(779.773 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6794"
                                          data-name="Path 6794"
                                          d="M6.923,17.428H5.729a.325.325,0,1,0,0,.65H6.923a.325.325,0,1,0,0-.65Z"
                                          transform="translate(779.967 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6795"
                                          data-name="Path 6795"
                                          d="M9.994,17.428H8.8a.325.325,0,1,0,0,.65H9.994a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.16 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6796"
                                          data-name="Path 6796"
                                          d="M13.066,17.428H11.872a.325.325,0,1,0,0,.65h1.194a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.354 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6797"
                                          data-name="Path 6797"
                                          d="M16.137,17.428H14.943a.325.325,0,1,0,0,.65h1.194a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.547 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6798"
                                          data-name="Path 6798"
                                          d="M3.852,20.454H2.658a.325.325,0,1,0,0,.65H3.852a.325.325,0,1,0,0-.65Z"
                                          transform="translate(779.773 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6799"
                                          data-name="Path 6799"
                                          d="M6.923,20.454H5.729a.325.325,0,1,0,0,.65H6.923a.325.325,0,1,0,0-.65Z"
                                          transform="translate(779.967 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6800"
                                          data-name="Path 6800"
                                          d="M9.994,20.454H8.8a.325.325,0,1,0,0,.65H9.994a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.16 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6801"
                                          data-name="Path 6801"
                                          d="M13.066,20.454H11.872a.325.325,0,1,0,0,.65h1.194a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.354 722)"
                                          fill="#1381a2" />
                                    <path id="Path_6802"
                                          data-name="Path 6802"
                                          d="M16.137,20.454H14.943a.325.325,0,1,0,0,.65h1.194a.325.325,0,1,0,0-.65Z"
                                          transform="translate(780.547 722)"
                                          fill="#1381a2" />
                                </g>
                            </svg>
                        </a>
                    </li>
                    <!--<li>
                      <a href="/#!testimonials">
                      <span>testimonials</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="24.399"
                          viewBox="0 0 30 24.399"
                        >
                          <g
                            id="Group_6332"
                            data-name="Group 6332"
                            transform="translate(-974 -721)"
                          >
                            <path
                              id="Path_6753"
                              data-name="Path 6753"
                              d="M20.392,0H9.608a9.608,9.608,0,0,0,0,19.216H10.4v4.808a.375.375,0,0,0,.608.294l6.428-5.1h2.961A9.608,9.608,0,0,0,20.392,0Zm0,18.466H17.3a.379.379,0,0,0-.233.081l-5.922,4.7V18.841a.375.375,0,0,0-.375-.375H9.608A8.858,8.858,0,0,1,9.608.75H20.392a8.858,8.858,0,0,1,0,17.716Z"
                              transform="translate(974 721)"
                              fill="#1381a2"
                            />
                            <path
                              id="Path_6754"
                              data-name="Path 6754"
                              d="M7.65,7.228A2.673,2.673,0,1,0,10.323,9.9,2.676,2.676,0,0,0,7.65,7.228Zm0,4.6A1.923,1.923,0,1,1,9.573,9.9,1.926,1.926,0,0,1,7.65,11.825Z"
                              transform="translate(974 721)"
                              fill="#1381a2"
                            />
                            <path
                              id="Path_6755"
                              data-name="Path 6755"
                              d="M15,7.228A2.673,2.673,0,1,0,17.674,9.9,2.676,2.676,0,0,0,15,7.228Zm0,4.6A1.923,1.923,0,1,1,16.924,9.9,1.926,1.926,0,0,1,15,11.825Z"
                              transform="translate(974 721)"
                              fill="#1381a2"
                            />
                            <path
                              id="Path_6756"
                              data-name="Path 6756"
                              d="M22.35,7.228A2.673,2.673,0,1,0,25.024,9.9,2.676,2.676,0,0,0,22.35,7.228Zm0,4.6A1.923,1.923,0,1,1,24.274,9.9,1.926,1.926,0,0,1,22.35,11.825Z"
                              transform="translate(974 721)"
                              fill="#1381a2"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>-->
                    <li>
                        <a href="/#!pricing-b">
                            <span>plans</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="21"
                                 height="21"
                                 viewBox="0 0 21 21">
                                <g id="Group_6334"
                                   data-name="Group 6334"
                                   transform="translate(-1096 -722)">
                                    <g id="Group_6333"
                                       data-name="Group 6333"
                                       transform="translate(-2.018 -3)">
                                        <path id="Path_6774"
                                              data-name="Path 6774"
                                              d="M10.246,9.618h-.127A2.4,2.4,0,0,1,7.55,7.457,2.394,2.394,0,0,1,10.119,5.3a2.393,2.393,0,0,1,2.568,2.161.566.566,0,0,0,1.133,0,3.444,3.444,0,0,0-3.135-3.251V3.288a.567.567,0,0,0-1.134,0v.917A3.442,3.442,0,0,0,6.417,7.457a3.52,3.52,0,0,0,3.7,3.293h.127a2.394,2.394,0,0,1,2.568,2.161,2.393,2.393,0,0,1-2.568,2.161,2.394,2.394,0,0,1-2.569-2.161.566.566,0,0,0-1.133,0,3.426,3.426,0,0,0,3.008,3.234v.935a.567.567,0,0,0,1.134,0v-.9a3.462,3.462,0,0,0,3.263-3.268A3.52,3.52,0,0,0,10.246,9.618Z"
                                              transform="translate(1098.327 725.138)"
                                              fill="#1381a2" />
                                        <g id="Ellipse_1299"
                                           data-name="Ellipse 1299"
                                           transform="translate(1098.018 725)"
                                           fill="none"
                                           stroke="#1381a2"
                                           stroke-width="0.75">
                                            <circle cx="10.5" cy="10.5" r="10.5" stroke="none" />
                                            <circle cx="10.5" cy="10.5" r="10.125" fill="none" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="footer-logo">
                <router-link to="/">
                    <img alt="logo" src="../../assets/images/logo2.svg">
                </router-link>
            </div>

        </div>
    </footer>
    </Base>
</template>

<script>
    import Base from "@/layouts/Base";
    import axios from 'axios';

    export default {
        name: "Contact",
        components: {
            Base,
        },
        props: {
            value: String
        },
        data() {
            return {
                inputHeight: '0',
                form: {
                    firstname: '',
                    lastname: '',
                    profession: '',
                    organisation: '',
                    phone: '',
                    organisationPhone: '',
                    email: '',
                    message: '',
                },
                showSuccessMessage: false,
            }
        },
        watch: {
            'form.message': () => {
                this.resize();
            }
        },
        computed: {
            inputStyle() {
                return {
                    'min-height': this.inputHeight
                }
            }
        },
        mounted() {
		
			



            let input
            let errors = 0
            let allRequired = document.querySelectorAll(".forced")
            let contactButton = document.querySelector("#contact-submit")

            Object.defineProperty(String.prototype, 'capitalize', {
                value: function () {
                    return this.charAt(0).toUpperCase() + this.slice(1);
                },
                enumerable: false
            });

            contactButton.addEventListener('click', (a) => {
                errors = 0
                checkInputs();

                if (errors == 0) {
                    console.log('sending')
                    setTimeout(() => {
                        //location.reload();
                    }, 15000)

                } else {
                    a.preventDefault()
                }
            })

            let checkInputs = () => {
                for (input of allRequired) {
                    if (input.value == "") {
                        if (input.classList.contains('redstyle')) {
                            errors += 1
                        } else {
                            errors += 1
                            input.classList.add('redstyle')
                        }
                    }
                }
            }
            this.resize()
        },
        methods: {
            resize() {
                this.inputHeight = `${this.$refs.input.scrollHeight}px`
            },
            async sendContact() {
                try {
                    await axios.get(process.env.VUE_APP_API_URL + '/csrf');
                    const response = await axios.post(process.env.VUE_APP_API_URL + '/contact', this.form);
                    this.showSuccessMessage = true;
                    console.log({ response });
                } catch (error) {
                    console.log({ error });
                }
            },
        }
    };
</script>






<style scoped>
    .redstyle::-webkit-input-placeholder {
        color: #dc3545
    }
</style>
